import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FiLoader } from 'react-icons/fi';

function ConsultationForm() {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!form.current.checkValidity()) {
      setLoading(false);
      toast.error('Please fill in all fields!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      return;
    }
    emailjs
      .sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, {
        publicKey: process.env.REACT_APP_PUBLIC_KEY,
      })
      .then(
        () => {
          setLoading(false);
          form.current.reset();
          toast.success('Request Submitted!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
          });
        },
        (error) => {
          setLoading(false);
          toast.error('Failed to submit request!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
          });
        },
      );
  };
  return (
    <>
      <form ref={form} onSubmit={sendEmail} className="w-[450px] max-[1024px]:w-[550px] h-fit md:h-[500px] bg-slate-100 rounded-2xl bg-white text-black flex shadow-2xl flex-col space-y-3 items-center justify-center p-5 border">
        <span className="font-bold text-black text-lg md:text-2xl">
          Get Free Consultation
        </span>
        <span className="!mb-[2rem] text-sm md:text-base">
          Leave your details below
        </span>
        <input required type="text" name="from_name" className="rounded-lg w-[85%] h-[40px] md:h-[50px] text-sm md:text-base px-3 border border-slate-400" placeholder="Full Name" />
        <input required type="email" name="from_email" className="rounded-lg w-[85%] h-[40px] md:h-[50px] text-sm md:text-base px-3 border border-slate-400" placeholder="Email Address" />
        <input required type="text" name="from_number" className="rounded-lg w-[85%] h-[40px] md:h-[50px] text-sm md:text-base px-3 border border-slate-400" placeholder="Mobile Number With Country Code" />
        <input required type="text" name="message" className="rounded-lg w-[85%] h-[40px] md:h-[50px] text-sm md:text-base px-3 border border-slate-400" placeholder="Project Description" />
        <span className="!mt-[2rem] font-bold text-sm md:text-lg">
          Your details are safe with us
        </span>
        {loading === true ? (
          <span className="text-red-500 text-sm md:text-base font-semibold flex items-center">
            <FiLoader className="animate-spin mr-2" />
            Submitting...
          </span>
        ) : (
          <button type="submit" className="w-fit rounded-full px-3 py-2 bg-red-500 text-white font-bold text-sm md:text-base cursor-pointer">
            Submit
          </button>
        )}
      </form>
    </>
  );
}

export default ConsultationForm;