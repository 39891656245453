import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './App.css';
import AppRoutes from './routes';

function App() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname?.includes('home')) {
      document.title = 'Schleuder Innovations';
    } else if (location.pathname?.includes('services')) {
      document.title = 'Services | Schleuder Innovations';
    } else if (location.pathname?.includes('contact-us')) {
      document.title = 'Contact Us | Schleuder Innovations';
    }
}, [location.pathname]);

  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
