import React from "react";
import WantToWork from "../../components/common/WantToWork";
import { FaConnectdevelop, FaGlobeAsia, FaRocket, FaShoppingBag } from "react-icons/fa";
import { MdAssignmentAdd, MdCampaign, MdHomeRepairService, MdMiscellaneousServices, MdOutlineDeveloperMode } from "react-icons/md";
import { RiCustomerService2Fill, RiMessage3Line, RiSeoLine } from "react-icons/ri";
import { GrServices } from "react-icons/gr";
import devImage from "../../assets/9910414.jpg";
import consultingImage from "../../assets/6183625.jpg";
import retailImage from "../../assets/55873.jpg";
import { GoGraph } from "react-icons/go";
import { IoDesktopOutline } from "react-icons/io5";
import { FaMobileScreen } from "react-icons/fa6";
import { PiFlowArrowDuotone, PiShoppingCart, PiStrategyFill } from "react-icons/pi";
import { GiShop } from "react-icons/gi";

function Services() {
  const [isWideScreen, setIsWideScreen] = React.useState(false);
  React.useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1024);
    };

    // Check the initial window size
    handleResize();
    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div className="md:space-y-[5rem] space-y-[1rem]">
      <div className="w-[100vw] min-h-[60vh] lg:h-screen xl:h-screen bg-no-repeat bg-cover pt-[80px] lg:pt-0 relative flex items-center justify-center">
        <div className="flex max-[1024px]:flex-col items-center justify-around px-[5rem] max-[1096px]:px-[2rem] text-white h-[100%] filter-none">
          <div className="flex w-full max-[1096px]:w-[100%] max-[1096px]:my-[4rem] flex-col items-center justify-center ">
            <span className="text-[2.5rem] md:text-[4rem] font-black !text-red-500 !filter-none text-center z-[10]">
              We Transform Your Ideas
            </span>
            <span className="text-[2.5rem] md:text-[4rem] font-black !text-red-500 !filter-none text-center z-[10]">
              Into Remarkable Solutions
            </span>
            <span className="text-black text-center text-wrap md:text-[1.5rem] mt-[2rem] z-[10]">
              Consult with our specialists to develop solutions that provide an exceptional user experience.
            </span>
            <span className="md:text-[1.5rem] text-black text-center z-[10]">
              Submit your project details and join our list of successful clients.
            </span>
          </div>
        </div>
        {isWideScreen && (
          <>
            <FaRocket className="absolute left-[8%] top-[20%] text-[2rem] md:text-[4rem] text-slate-300" />
            <FaConnectdevelop className="absolute md:right-[5rem] right-[2rem] md:top-[200px] top-[300px] text-[2rem] md:text-[4rem] text-slate-300 animate-spin" />
            <MdOutlineDeveloperMode className="absolute left-[17%] top-[50%] text-[2rem] md:text-[4rem] text-slate-300 animate-pulse" />
            <RiMessage3Line className="absolute right-[10%] top-[60%] text-[2rem] md:text-[4rem] text-slate-300" />
            <FaGlobeAsia className="absolute md:left-[47%] left-[47%] md:top-[170px] top-[210px] text-[2rem] md:text-[4rem] text-slate-300" />
            <MdHomeRepairService className="absolute right-[40%] top-[65%] text-[2rem] md:text-[4rem] text-slate-300" />
            <GrServices className="absolute left-[10%] top-[85%] text-[2rem] md:text-[4rem] text-slate-300" />
            <RiCustomerService2Fill className="absolute left-[40%] top-[80%] text-[2rem] md:text-[4rem] text-slate-300" />
            <MdCampaign className="absolute right-[20%] top-[85%] text-[2rem] md:text-[4rem] text-slate-300" />
          </>
        )}
      </div>
      <section id="development" className="w-full hover:bg-slate-50 py-3 flex flex-col items-center justify-center">
        <div className="md:w-[80%] md:space-x-4 md:h-[21rem] h-fit w-full flex flex-col md:flex-row items-center justify-around">
          <div className="w-[21rem] max-w-[21rem]">
            <img src={devImage} alt="Development" className="w-full h-full rounded-xl object-cover" />
          </div>
          <div className="flex flex-col md:w-[70%] w-[80%] md:pt-[2rem] text-justify h-full items-center md:items-start justify-start">
            <div className="flex items-center w-full justify-center pt-[1rem] md:pt-0">
              <span className="text-[1.9rem] lg:text-[2.5rem] font-bold text-red-500">
                Development
              </span>
              <MdOutlineDeveloperMode className="text-[2rem] text-red-500 ml-[1rem]" />
            </div>
            <div className="mt-[2rem]">
              <span className="text-sm md:text-base text-slate-500">
                We offer a comprehensive range of services to meet your digital needs. Our expertise includes app development, web development, and e-commerce development, ensuring that your online presence is robust and user-friendly. Additionally, we provide top-notch SEO services to enhance your search engine visibility and social media marketing to boost your brand's online engagement. Let us help you transform your ideas into remarkable solutions.
              </span>
            </div>
          </div>
        </div>
        <div className="md:w-[80%] flex items-center justify-center mt-[2rem] lg:mt-0">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 p-4 gap-[2rem] md:gap-[1rem] max-w-[80%] md:max-w-[100%] !w-fit">
            <div className="border border-dashed shadow-inner p-3 flex items-center justify-center flex-col rounded-2xl bg-white min-h-[17rem] w-full">
              <IoDesktopOutline className="text-[2rem] text-red-500 mb-[0.5rem]" />
              <div className="text-lg text-semibold">
                Web Development
              </div>
              <div className="w-[90%] text-justify overflow-y-scroll">
                <span className="text-sm text-slate-500 hyphens-auto">
                  We cover all your needs, utilizing the latest technologies like React, Next.js, and Node.js, along with robust databases such as PostgreSQL and MongoDB. We ensure your website is modern, scalable, and efficient.
                </span>
              </div>
            </div>
            <div className="border border-dashed shadow-inner p-3 flex items-center justify-center flex-col rounded-2xl bg-white min-h-[17rem] w-full">
              <FaMobileScreen className="text-[2rem] text-red-500 mb-[0.5rem]" />
              <div className="text-lg text-semibold">
                App Development
              </div>
              <div className="w-[90%] text-justify overflow-y-scroll">
                <span className="text-sm text-slate-500 hyphens-auto">
                  Our app development services leverage cutting-edge technologies like Flutter, React Native, and Kotlin. We build high-performance, cross-platform apps tailored to meet your business needs and deliver a seamless user experience.
                </span>
              </div>
            </div>
            <div className="border border-dashed shadow-inner p-3 flex items-center justify-center flex-col rounded-2xl bg-white min-h-[17rem] w-full">
              <MdCampaign className="text-[2rem] text-red-500 mb-[0.5rem]" />
              <div className="text-lg text-semibold">
                Digital Marketing
              </div>
              <div className="w-[90%] text-justify overflow-y-scroll">
                <span className="text-sm text-slate-500 hyphens-auto">
                  Our digital marketing services help amplify your brand's online presence. We craft targeted strategies to engage your audience, boost your visibility, and drive growth across all major social platforms.
                </span>
              </div>
            </div>
            <div className="border border-dashed shadow-inner p-3 flex items-center justify-center flex-col rounded-2xl bg-white min-h-[17rem] w-full">
              <RiSeoLine className="text-[2rem] text-red-500 mb-[0.5rem]" />
              <div className="text-lg text-semibold">
                SEO Services
              </div>
              <div className="w-[90%] text-justify overflow-y-scroll">
                <span className="text-sm text-slate-500 hyphens-auto">
                  Our SEO services are designed to elevate your online visibility and drive organic traffic. We use proven strategies to optimize your website, improve search engine rankings, and enhance your overall digital presence.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="consulting" className="w-full hover:bg-slate-50 py-3 flex flex-col items-center justify-center">
        <div className="md:w-[80%] space-x-4 md:h-[21rem] h-fit w-full flex flex-col-reverse md:flex-row items-center justify-around">
          <div className="flex flex-col md:w-[70%] w-[80%] md:pt-[2rem] text-justify h-full items-center md:items-start justify-start">
            <div className="flex items-center w-full justify-center mt-[2rem] lg:mt-0">
              <span className="text-[1.9rem] lg:text-[2.5rem] font-bold text-red-500">
                Consulting
              </span>
              <GoGraph className="text-[2rem] text-red-500 ml-[1rem]" />
            </div>
            <div className="mt-[1rem]">
              <span className="text-sm md:text-base text-slate-500 hyphens-auto">
                Our consulting services cover a broad spectrum to support your business needs. We offer expert guidance in Strategy to help you define clear objectives and achieve your goals. Our Implementation services ensure seamless execution of your plans, while our Operation support optimizes your processes for efficiency. Additionally, our Managed Services provide ongoing support and management to keep your operations running smoothly. Let us partner with you to drive success and growth at every stage.              </span>
            </div>
          </div>
          <div className="w-[21rem] max-w-[21rem]">
            <img src={consultingImage} alt="Development" className="w-full h-full rounded-xl object-cover" />
          </div>
        </div>
        <div className="md:w-[80%] flex items-center justify-center mt-[2rem] lg:mt-0">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 p-4 gap-[2rem] md:gap-[1rem] max-w-[80%] md:max-w-[100%] !w-fit">
            <div className="border border-dashed shadow-inner p-3 flex items-center justify-center flex-col rounded-2xl bg-white min-h-[17rem] w-full">
              <PiStrategyFill className="text-[2rem] text-red-500 mb-[0.5rem]" />
              <div className="text-lg text-semibold">
                Strategy
              </div>
              <div className="w-[90%] text-justify overflow-y-scroll">
                <span className="text-sm text-slate-500 hyphens-auto">
                  Our Strategy consulting services focus on aligning your business goals with actionable plans. We analyze your market, define your objectives, and develop tailored strategies to drive growth and success. Let us help you craft a clear roadmap for achieving your vision.                
                </span>
              </div>
            </div>
            <div className="border border-dashed shadow-inner p-3 flex items-center justify-center flex-col rounded-2xl bg-white min-h-[17rem] w-full">
              <MdAssignmentAdd className="text-[2rem] text-red-500 mb-[0.5rem]" />
              <div className="text-lg text-semibold">
                Implementation
              </div>
              <div className="w-[90%] text-justify overflow-y-scroll">
                <span className="text-sm text-slate-500 hyphens-auto">
                  Our Implementation services ensure that your strategic plans are executed efficiently and effectively. We handle the details of project rollouts, coordinate resources, and manage timelines to bring your vision to life with precision and success.                
                </span>
              </div>
            </div>
            <div className="border border-dashed shadow-inner p-3 flex items-center justify-center flex-col rounded-2xl bg-white min-h-[17rem] w-full">
              <PiFlowArrowDuotone className="text-[2rem] text-red-500 mb-[0.5rem]" />
              <div className="text-lg text-semibold">
                Operation
              </div>
              <div className="w-[90%] text-justify overflow-y-scroll">
                <span className="text-sm text-slate-500 hyphens-auto">
                  Our Operation consulting services streamline your business processes for maximum efficiency. We analyze and optimize workflows, improve operational performance, and implement best practices to enhance productivity and achieve consistent results.
                </span>
              </div>
            </div>
            <div className="border border-dashed shadow-inner p-3 flex items-center justify-center flex-col rounded-2xl bg-white min-h-[17rem] w-full">
              <MdMiscellaneousServices className="text-[2rem] text-red-500 mb-[0.5rem]" />
              <div className="text-lg text-semibold">
                Manage Service
              </div>
              <div className="w-[90%] text-justify overflow-y-scroll">
                <span className="text-sm text-slate-500 hyphens-auto">
                  Our Managed Services provide ongoing support and oversight to keep your operations running smoothly. We handle the day-to-day management, monitor performance, and ensure that your systems are maintained and optimized, so you can focus on growing your business.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="#retail" className="w-full hover:bg-slate-50 py-3 flex flex-col items-center justify-center">
        <div className="md:w-[80%] space-x-4 md:h-[21rem] h-fit w-full flex flex-col md:flex-row items-center justify-around">
          <div className="w-[21rem] max-w-[21rem]">
            <img src={retailImage} alt="Development" className="w-full h-full rounded-xl object-cover" />
          </div>
          <div className="flex flex-col md:w-[70%] w-[80%] md:pt-[2rem] text-justify h-full items-center md:items-start justify-start">
            <div className="flex items-center w-full justify-center pt-[1rem] md:pt-0">
              <span className="text-[1.9rem] lg:text-[2.5rem] font-bold text-red-500">
                Retail
              </span>
              <PiShoppingCart className="text-[2rem] text-red-500 ml-[1rem]" />
            </div>
            <div className="mt-[1rem]">
              <span className="text-sm md:text-base text-slate-500 hyphens-auto">
                Our Retail services offer a comprehensive approach to enhance your business. We manage efficient wholesale distribution to streamline your supply chain and ensure timely deliveries. Additionally, our e-commerce solutions are tailored to optimize sales across various online platforms, providing a smooth and effective shopping experience. Let us help you boost your retail operations with strategic and innovative solutions.              
              </span>
            </div>
          </div>
        </div>
        <div className="md:w-[80%] flex items-center justify-center mt-[2rem] lg:mt-0">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 p-4 gap-[2rem] md:gap-[1rem] max-w-[80%] md:max-w-[100%] !w-fit">
            <div className="border border-dashed shadow-inner p-3 flex items-center justify-center flex-col rounded-2xl bg-white min-h-[17rem] w-full">
              <GiShop className="text-[2rem] text-red-500 mb-[0.5rem]" />
              <div className="text-lg text-semibold">
                Wholesale Distribution
              </div>
              <div className="w-[90%] text-justify overflow-y-scroll">
                <span className="text-sm text-slate-500 hyphens-auto">
                  Our Wholesale Distribution services focus on managing and streamlining the distribution of products. We ensure efficient handling, timely delivery, and optimal inventory management, supporting both our own sales and those of our clients. Let us help you enhance your distribution processes for greater business success.                
                </span>
              </div>
            </div>
            <div className="border border-dashed shadow-inner p-3 flex items-center justify-center flex-col rounded-2xl bg-white min-h-[17rem] w-full">
              <FaShoppingBag className="text-[2rem] text-red-500 mb-[0.5rem]" />
              <div className="text-lg text-semibold">
                E-commerce Solutions
              </div>
              <div className="w-[90%] text-justify overflow-y-scroll">
                <span className="text-sm text-slate-500 hyphens-auto">
                  Our E-commerce Solutions focus on selling products across multiple online platforms. We manage everything from product listings to inventory, ensuring a seamless and efficient sales process. Trust us to optimize our online presence and drive sales growth across various e-commerce channels.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WantToWork />
    </div>
  );
}

export default Services;