/* eslint-disable max-len */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import SharedLayout from './pages/sharedLayout/SharedLayout';
import Home from './pages/Home/Home';
import Services from './pages/Services/Services';
import ContactUs from './pages/Contact/ContactUs';

function AppRoutes() {
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <div>
      <Routes>
        <Route element={<Outlet />}>
        {/* <Route path="/" element={<Navigate to="/p/home" replace />} /> */}
          <Route path="/*" element={<SharedLayout />}>
            <Route path="" element={<Home />} />
            <Route path="services" element={<Services />} />
            <Route path="contact-us" element={<ContactUs />} />
        </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default AppRoutes;
