import React, { useEffect, useState } from 'react';
import ConsultationForm from '../../components/Home/ConsultationForm';
import { GoGraph } from 'react-icons/go';
import { IoCodeSlashOutline, IoDesktopOutline } from 'react-icons/io5';
import { PiFlowArrowDuotone, PiShoppingCart, PiStrategyFill } from 'react-icons/pi';
import { FaMobileScreen } from 'react-icons/fa6';
import { MdAssignmentAdd, MdCampaign, MdMiscellaneousServices, MdOutlineMiscellaneousServices } from 'react-icons/md';
import { RiSeoLine } from 'react-icons/ri';
import { FaShoppingBag } from 'react-icons/fa';
import { GiShop } from 'react-icons/gi';
import WantToWork from '../../components/common/WantToWork';
import backgroundImage from '../../assets/newtest.jpg';
import mobBack from '../../assets/mobBack.jpg';

function Home() {
  const [backImage, setBackImage] = useState(backgroundImage);

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth > 768) {
          setBackImage(backgroundImage);
        } else {
          setBackImage(mobBack);
        }
      };

      handleResize();

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

  return (
    <div className="relative space-y-[5rem]">
      <div className="w-[100vw] min-h-screen lg:h-screen xl:h-screen bg-no-repeat bg-cover bg-center pt-[80px] rounded-b-3xl md:rounded-b-none shadow-[0_30px_50px_0_rgba(0,0,0,0.7)]" style={{ backgroundImage: `url(${backImage})` }}>
        <div className="flex max-[1024px]:flex-col items-center justify-around px-[5rem] max-[1096px]:px-[2rem] text-white h-[100%] filter-none">
          <div className="flex w-[50%] max-[1096px]:w-[100%] max-[1096px]:my-[4rem] flex-col items-center justify-center ">
            <div className="border-l-[8px] rounded border-red-500 w-full md:w-[80%]">
              <div className="flex flex-col items-start justify-start pl-[1rem]">
                <span className="text-[2.7rem] md:text-[4rem] font-black !text-red-500 !filter-none">
                  Your Vision,
                </span>
                <span className="text-[2.7rem] md:text-[4rem] font-black !text-red-500 !filter-none">
                  Our Expertise
                </span>
              </div>
            </div>
            <span className="text-[17px] md:text-[25px] pl-0 md:pl-[2rem] mt-[1rem] font-semibold text-white w-full md:w-[85%]">
              Expert Consulting and Cutting-Edge Development Solutions
            </span>
          </div>
          <div className="w-[50%] max-[1096px]:w-[100%] h-full flex items-center justify-center max-[1096px]:mb-[5rem]">
            <ConsultationForm />
          </div>
        </div>
      </div>
      <div className="h-fit w-[100vw] flex flex-col items-center justify-start">
        <div className="text-[1.5rem] sm:text-[2.5rem] font-semibold text-black mx-[2rem] flex items-center">
          Services
          <MdOutlineMiscellaneousServices className="text-red-500 text-[2.5rem] ml-[1rem]" />
        </div>
        <div className="grid grid-cols-1 gap-2 flex w-full lg:px-[5rem] mt-[2rem] md:grid-cols-3 sm:grid-cols-2">
          <div className="p-[2rem] min-h-[24rem]">
            <div className="w-full h-full items-center justify-around flex flex-col border rounded-2xl shadow cursor-pointer p-[1rem]">
              <div className="w-full h-fit items-center justify-center flex space-x-2">
                <IoCodeSlashOutline className="text-xl md:text-[2rem] lg:text-[3rem] text-red-500" />
                <span className="font-semibold text-xl sm:text-base lg:text-2xl text-red-500">
                  Development
                </span>
              </div>
              <div className="w-fit h-fit items-start justify-center text-slate-600 flex flex-col space-y-[1.5rem] font-semibold text-lg sm:text-base lg:text-xl">
                <div className="flex items-center space-x-4">
                  <IoDesktopOutline />
                  <span>
                    Web Development
                  </span>
                </div>
                <div className="flex items-center space-x-4">
                  <FaMobileScreen />
                  <span>
                    App Development
                  </span>
                </div>
                <div className="flex items-center space-x-4">
                  <MdCampaign />
                  <span>
                    Digital Marketing
                  </span>
                </div>
                <div className="flex items-center space-x-4">
                  <RiSeoLine />
                  <span>
                    SEO Services
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="p-[2rem] min-h-[24rem]">
            <div className="w-full h-full items-center justify-around flex flex-col border rounded-2xl shadow cursor-pointer p-[1rem]">
              <div className="w-full h-fit items-center justify-center flex space-x-2">
                <GoGraph className="text-xl md:text-[2rem] lg:text-[3rem] text-red-500" />
                <span className="font-semibold text-xl sm:text-base lg:text-2xl text-red-500">
                  Consulting
                </span>
              </div>
              <div className="w-fit h-fit items-start justify-center text-slate-600 flex flex-col space-y-[1.5rem] font-semibold text-lg sm:text-base lg:text-xl">
                <div className="flex items-center space-x-4">
                  <PiStrategyFill />
                  <span>
                    Strategy
                  </span>
                </div>
                <div className="flex items-center space-x-4">
                  <MdAssignmentAdd />
                  <span>
                    Implementation
                  </span>
                </div>
                <div className="flex items-center space-x-4">
                  <PiFlowArrowDuotone />
                  <span>
                    Operation
                  </span>
                </div>
                <div className="flex items-center space-x-4">
                  <MdMiscellaneousServices />
                  <span>
                    Manage Service
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="p-[2rem] min-h-[24rem]">
            <div className="w-full h-full items-center justify-around flex flex-col border rounded-2xl shadow cursor-pointer p-[1rem]">
              <div className="w-full h-fit items-center justify-center flex space-x-2">
                <PiShoppingCart className="text-xl md:text-[2rem] lg:text-[3rem] text-red-500" />
                <span className="font-semibold text-xl sm:text-base lg:text-2xl text-red-500">
                  Retail
                </span>
              </div>
              <div className="w-fit h-fit items-start justify-center text-slate-600 flex flex-col space-y-[1.5rem] font-semibold text-lg sm:text-base lg:text-xl">
                <div className="flex items-center space-x-4">
                  <GiShop />
                  <span>
                    Wholesale Distribution
                  </span>
                </div>
                <div className="flex items-center space-x-4">
                  <FaShoppingBag />
                  <span>
                    E-commerce Solutions
                  </span>
                </div>
                <div className="opacity-0 flex items-center space-x-4">
                  <MdCampaign />
                  <span>
                    Digital Marketing
                  </span>
                </div>
                <div className="opacity-0 flex items-center space-x-4">
                  <RiSeoLine />
                  <span>
                    SEO Services
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WantToWork />
    </div>
  );
}

export default Home;