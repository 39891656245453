import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/schleuderLarge.png'
import logoWhite from '../../assets/schleuderLargeWhite.png'
import { FaTimes } from 'react-icons/fa';
import { FaBars } from 'react-icons/fa6';
import Footer from '../../components/common/Footer';
import { ToastContainer } from 'react-toastify';

function SharedLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsScrolled(false);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="flex flex-start h-[100vh] w-[100vw] relative flex-wrap">
      <nav className={`w-full flex justify-between px-[1rem] sm:px-[5rem] ${window.location.pathname === '/' ? (isScrolled || isOpen) ? 'bg-white border-b text-black h-[100px]' : 'bg-transparent text-white h-[120px]' : isScrolled ? 'bg-white border-b text-black h-[100px]' : 'bg-white text-black h-[100px]'} items-center fixed top-0 z-[100] transition-all duration-150`}>
        <div onClick={() => { navigate('/'); setIsOpen(false); }} className={`${isScrolled ? 'h-[80px]' : 'h-[120px]'} flex items-center transition-all duration-300 cursor-pointer`}>
          <img src={window.location.pathname === '/' ? (isScrolled || isOpen) ? logo : logoWhite : logo} alt="logo" className={`${window.location.pathname === '/' ? isScrolled ? 'h-[60px] sm:h-[80px]' : 'h-[80px] sm:h-[100px]' : 'h-[60px] sm:h-[80px]'} transition-all duration-300`} />
        </div>
        <div className="lg:hidden flex items-center">
          <button onClick={toggleMenu} className="text-2xl focus:outline-none">
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
        <div className={`lg:flex items-center justify-between ${isOpen ? 'block !flex-col !flex space-y-[2rem] py-[1rem] rounded-b-3xl border-b shadow-2xl w-full ' : 'hidden space-x-[3rem]'} lg:block absolute top-[100px] left-0 w-full bg-white lg:static lg:w-auto lg:bg-transparent lg:mt-0 lg:p-0 transition-all duration-150`}>
          <span onClick={() => { navigate('/'); setIsOpen(false); }} className={`cursor-pointer sm:text-lg py-2 px-4 lg:px-0 lg:py-0 hover:text-red-500 ${window.location?.pathname === '/' && 'text-red-500 font-semibold'} ${isOpen === true && 'w-full text-center' }`}>
            Home
          </span>
          <span onClick={() => { navigate('/services'); setIsOpen(false); }} className={`cursor-pointer sm:text-lg py-2 px-4 lg:px-0 lg:py-0 hover:text-red-500 ${window.location?.pathname?.includes('service') && 'text-red-500 font-semibold'} ${isOpen === true && 'w-full text-center' }`}>
            Services
          </span>
          {/* <span onClick={() => { navigate('/about'); setIsOpen(false); }} className={`cursor-pointer text-lg py-2 px-4 lg:px-0 lg:py-0 hover:text-red-500 ${window.location?.pathname?.includes('about') && 'text-red-500 font-semibold'}`}>
            About Us
          </span> */}
          <span onClick={() => { navigate('/contact-us'); setIsOpen(false); }} className={`cursor-pointer sm:text-lg py-2 px-4 lg:px-0 lg:py-0 hover:text-red-500 ${window.location?.pathname?.includes('contact') && 'text-red-500 font-semibold'} ${isOpen === true && 'w-full text-center' }`}>
            Contact Us
          </span>
        </div>
      </nav>
      <Outlet />
      <div className="w-[100vw] mt-[5rem]">
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
}

export default SharedLayout;