import React from 'react';
import contactImage from '../../assets/5121921.jpg';
import WantToWork from '../../components/common/WantToWork';
import { PiPhoneFill } from 'react-icons/pi';
import { MdEmail } from 'react-icons/md';

function ContactUs() {
  return (
    <div className="mt-[120px] w-full">
      <div className="flex flex-col md:flex-row items-center w-full mb-[2rem] justify-around px-[2rem] lg:px-[5rem]">
        <div className="w-min">
          <img src={contactImage} alt="Contact Us" className="w-[30%] min-w-[24rem] md:min-w-[20rem] lg:min-w-[26rem]" />
        </div>
        <div className="md:w-[60%] text-center md:text-start">
          <div>
            <span className="text-[3rem] md:text-[4rem] font-black !text-red-500 !filter-none">
              Contact Us
            </span>
          </div>
          <div>
            <span className="md:text-lg font-semibold">
              We’d love to hear from you! Whether you have a question about our services, need assistance with a project, or want to discuss a new opportunity, our team is here to help.
            </span>
          </div>
          <div className="flex flex-col justify-start w-full mt-[2rem] space-y-4">
            <div className="flex flex-row items-center">
              <PiPhoneFill className="text-red-500 text-[2rem]" />
              <a href="tel:+918077388166" className="md:text-lg font-semibold ml-[1rem]">
                +91 8077388166
              </a>
            </div>  
            <div className="flex flex-row items-center">
              <MdEmail className="text-red-500 text-[2rem]" />
              <a href="mailto:schleuderinnovations@gmail.com" className="md:text-lg font-semibold ml-[1rem]">
                schleuderinnovations@gmail.com
              </a>
            </div>  
          </div>
        </div>
      </div>
      <WantToWork />
    </div>
  );
}

export default ContactUs;