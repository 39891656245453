import React from 'react';
import contactImage from '../../assets/contact.webp';
import { MdRocketLaunch } from 'react-icons/md';
import ConsultationForm from '../Home/ConsultationForm';

function WantToWork() {
  return (
    <div className="h-fit w-[100vw] flex flex-col items-center justify-start">
      <div className="text-[1.5rem] sm:text-[2.5rem] font-semibold text-black mx-[2rem] text-center items-center flex">
        Want to work with us?
        <MdRocketLaunch className="text-red-500 text-[2.5rem] ml-[1rem]" />
      </div>
      <div className="w-full items-center justify-center flex lg:flex-row mt-[5rem] px-[2rem] lg:space-x-[6rem] xl:space-x-[10rem]">
        <ConsultationForm />
        <div className="max-[1096px]:hidden">
          <img src={contactImage} alt="Contact Us" className="h-[350px]" />
        </div>
      </div>
    </div>
  );
}

export default WantToWork;