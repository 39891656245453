import React from 'react';
import footerLogo from '../../assets/schleuderTextWhite.png';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();
  return (
    <div className="w-full h-[20rem] md:h-[12rem] bg-black flex flex-col items-center justify-center">
      <div className="flex flex-col md:flex-row items-center justify-between w-full h-full px-[2rem] py-[2rem] xl:px-[5rem] space-y-[2rem] md:space-y-0">
        <div onClick={() => navigate('/')}>
          <img src={footerLogo} alt="Schleuder Logo" className="h-[100px] cursor-pointer" />
        </div>
        <div className="text-white grid grid-cols-3 text-center gap-6 text-sm sm:text-base">
          <span onClick={() => navigate('/')} className="cursor-pointer">
            Home
          </span>
          <span onClick={() => navigate('/services')} className="cursor-pointer">
            Services
          </span>
          {/* <span onClick={() => navigate('/about')} className="cursor-pointer">
            About Us
          </span> */}
          <span onClick={() => navigate('/contact-us')} className="cursor-pointer">
            Contact Us
          </span>
        </div>
      </div>
      <div className="w-full h-[5rem] flex items-center justify-center text-center text-white mb-[2rem] sm:text-base text-xs">
        Copyright 2024 © Schleuder Innovations. All rights reserved.
      </div>
    </div>
  );
}

export default Footer;